import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "COBOL と ORCA について学ぶお話",
  "date": "2017-04-18T08:49:18.000Z",
  "slug": "entry/2017/04/18/174918",
  "tags": ["medley"],
  "hero": "./2017_04_18.png",
  "heroAlt": "cobol"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><strong parentName="p">{`TechLunch で`}<a parentName="strong" {...{
          "href": "https://d.hatena.ne.jp/keyword/COBOL"
        }}>{`COBOL`}</a>{`と`}<a parentName="strong" {...{
          "href": "https://d.hatena.ne.jp/keyword/ORCA"
        }}>{`ORCA`}</a>{`について話しました`}</strong></p>
    <p>{`開発本部の竹内です。病気事典 MEDLEY の開発を担当しております。子どもが絶賛イヤイヤ期中です。
さて先日、TechLunch という社内勉強会にて`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/COBOL"
      }}>{`COBOL`}</a>{`と`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/ORCA"
      }}>{`ORCA`}</a>{`についての発表を行いましたので、その紹介をさせていただきたいと思います。`}</p>
    <h1>{`はじめに`}</h1>
    <p><a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/COBOL"
      }}>{`COBOL`}</a>{`とは？`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/ORCA"
      }}>{`ORCA`}</a>{`とは？の前に、「なぜ今回このテーマを選んだか」について簡単に説明させてください。`}</p>
    <p>{`株式会社メドレーでは「医療ヘルスケア分野の課題を解決する」ために各サービスの開発・運営を行っています。
しかし、「医療ヘルスケア分野」のサービス開発・運営を行っていると言っても、実際の開発現場では一般的な Web アプリケーションや`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/%A5%B9%A5%DE%A1%BC%A5%C8%A5%D5%A5%A9%A5%F3"
      }}>{`スマートフォン`}</a>{`アプリケーションの開発を行うことが多々あります。
そのため、日々の仕事において「医療ヘルスケア分野」で業務を行っていると意識しないこともあります。
せっかく「医療 ×IT」の会社にいるわけなので、エンジニアとして医療に深く関わるソフトウェアについて勉強してみるのも面白いのではないか、と思ったことがテーマの選択につながっています。
また、医療ヘルスケア分野で`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/OSS"
      }}>{`OSS`}</a>{`として公開されている代表的なものとして`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/ORCA"
      }}>{`ORCA`}</a>{`の「日医標準レセプトソフト」がある、というのもテーマ選択の理由の一つです。
どんなソフトウェアにせよそれが`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/OSS"
      }}>{`OSS`}</a>{`であれば、コードが公開されているため勉強を始めるハードルはかなり下がるため、です。`}</p>
    <h1><a parentName="h1" {...{
        "href": "https://d.hatena.ne.jp/keyword/ORCA"
      }}>{`ORCA`}</a>{`とは`}</h1>
    <blockquote>
      <p parentName="blockquote"><a parentName="p" {...{
          "href": "https://d.hatena.ne.jp/keyword/ORCA"
        }}>{`ORCA`}</a>{`プロジェクトとは、医療情報ネットワーク推進委員会にて「医師会総合情報ネットワーク構想」（1997 年 情報化検討委員会）を構成するツールの一つとして認められた`}<a parentName="p" {...{
          "href": "https://d.hatena.ne.jp/keyword/%C6%FC%CB%DC%B0%E5%BB%D5%B2%F1"
        }}>{`日本医師会`}</a>{`の研究事業プロジェクトです。 `}<a parentName="p" {...{
          "href": "https://www.orca.med.or.jp/orca/summary/outline.html"
        }}>{`ORCA Project： ORCA プロジェクトの概要`}</a></p>
    </blockquote>
    <p><a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/ORCA"
      }}>{`ORCA`}</a>{`とは`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/%C6%FC%CB%DC%B0%E5%BB%D5%B2%F1"
      }}>{`日本医師会`}</a>{`のプロジェクトで、国民医療を改善するために医療情報の効率化・標準化を推進するために、医師・医療関係機関が無料で利用・改良できる`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/OSS"
      }}>{`OSS`}</a>{`を配布しています。`}</p>
    <p>{`現在、診療報酬を請求するための専用コンピュータ（＝レセコン）は、全国の`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/%B0%E5%CE%C5%B5%A1%B4%D8"
      }}>{`医療機関`}</a>{`の内８割以上が使用しているそうです。`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/ORCA"
      }}>{`ORCA`}</a>{`プロジェクトでは「日医標準レセプトソフト（＝日レセ）」という`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/OSS"
      }}>{`OSS`}</a>{`が公開されています。`}</p>
    <p><a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/OSS"
      }}>{`OSS`}</a>{`なのでもちろんコードは公開されており、その他の技術情報も下記リンク先に詳細が掲載されています。 `}<a parentName="p" {...{
        "href": "https://www.orca.med.or.jp/receipt/tec/"
      }}>{`ORCA Project： 技術情報`}</a></p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20170417/20170417154614.png",
        "alt": "f:id:medley_inc:20170417154614p:plain",
        "title": "f:id:medley_inc:20170417154614p:plain"
      }}></img></p>
    <h1><a parentName="h1" {...{
        "href": "https://d.hatena.ne.jp/keyword/COBOL"
      }}>{`COBOL`}</a>{`とは`}</h1>
    <p>{`さて、上記リンク先を見いくと日医標準レセプトソフトのコードは`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/CVS"
      }}>{`CVS`}</a>{`で公開されており、その中を見てみると`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/COBOL"
      }}>{`COBOL`}</a>{`のコードがあることがあわかります。
個人的に`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/COBOL"
      }}>{`COBOL`}</a>{`については、「名前は聞いたことあるけど、文法など詳細は知らない割になぜか良い印象を持っていない」という状態でした。
レセコンのロジックを勉強するにあたり、ついでに未知の言語である`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/COBOL"
      }}>{`COBOL`}</a>{`も勉強してみよう、という軽いノリで調べはじめました。`}</p>
    <p>{`勉強会では`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/COBOL"
      }}>{`COBOL`}</a>{`の基本的な文法にフォーカスして紹介しています。
また、今回は`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/COBOL"
      }}>{`COBOL`}</a>{`の実行環境として Docker を利用しています。`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://d.hatena.ne.jp/keyword/COBOL"
        }}>{`COBOL`}</a>{`による HELLO, WORLD`}</li>
    </ul>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "cobol"
    }}><pre parentName="div" {...{
        "className": "language-cobol"
      }}><code parentName="pre" {...{
          "className": "language-cobol"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`IDENTIFICATION`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`DIVISION`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token keyword"
          }}>{`PROGRAM-ID`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{` HELLO_WORLD`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`ENVIRONMENT`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`DIVISION`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`DATA`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`DIVISION`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`PROCEDURE`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`DIVISION`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`DISPLAY`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Hello, COBOL"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`STOP`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`RUN`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span></code></pre></div>
    <h1><a parentName="h1" {...{
        "href": "https://d.hatena.ne.jp/keyword/ORCA"
      }}>{`ORCA`}</a>{`のコードについて`}</h1>
    <p>{`上述の通り、`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/ORCA"
      }}>{`ORCA`}</a>{`（日レセ）のコードは`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/CVS"
      }}>{`CVS`}</a>{`で管理されているため、`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/Mac"
      }}>{`Mac`}</a>{`では次のようにしてコードを取得できるかと思います。`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "shell"
    }}><pre parentName="div" {...{
        "className": "language-shell"
      }}><code parentName="pre" {...{
          "className": "language-shell"
        }}><span parentName="code" {...{
            "className": "token comment"
          }}>{`# 参考：https://www.orca.med.or.jp/receipt/tec/cvs-jma-receipt.html\\# リポジトリ取得`}</span>{`
$ brew `}<span parentName="code" {...{
            "className": "token function"
          }}>{`install`}</span>{` cvs
$ `}<span parentName="code" {...{
            "className": "token function"
          }}>{`curl`}</span>{` https://ftp.orca.med.or.jp/pub/data/receipt/tec/orcacvs `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` ~/bin/orcacvs
$ `}<span parentName="code" {...{
            "className": "token function"
          }}>{`chmod`}</span>{` +x ~/bin/orcacvs
$ cvs -d :ext:anoncvs@cvs.orca.med.or.jp:/cvs co jma-receipt`}</code></pre></div>
    <p>{`実際の`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/COBOL"
      }}>{`COBOL`}</a>{`のコードは`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/cobol"
      }}>{`cobol`}</a>{`という`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/%A5%C7%A5%A3%A5%EC%A5%AF%A5%C8"
      }}>{`ディレクト`}</a>{`リに入っています。
`}<a parentName="p" {...{
        "href": "https://cvs.orca.med.or.jp/cgi-bin/cvsweb/jma-receipt/cobol/"
      }}>{`https://cvs.orca.med.or.jp/cgi-bin/cvsweb/jma-receipt/cobol/`}</a></p>
    <p>{`見てみると、ファイルを開いてコメントを確認するまでどれがどういう用途なのかよくわかりませんでした。
ファイル内のコメントにある「`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/%A5%B3%A5%F3%A5%DD%A1%BC%A5%CD%A5%F3%A5%C8"
      }}>{`コンポーネント`}</a>{`名」と cvsweb の URL を対応付けた`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/markdown"
      }}>{`markdown`}</a>{`を作成しましたので、興味がある方は見てみてください。`}</p>
    <p><a parentName="p" {...{
        "href": "https://github.com/ise/orca-jma-receipt-study/blob/master/cobol.md"
      }}>{`https://github.com/ise/orca-jma-receipt-study/blob/master/cobol.md`}</a></p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20170417/20170417154633.png",
        "alt": "f:id:medley_inc:20170417154633p:plain",
        "title": "f:id:medley_inc:20170417154633p:plain"
      }}></img></p>
    <h1>{`まとめ`}</h1>
    <p>{`TechLunch で`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/ORCA"
      }}>{`ORCA`}</a>{`と`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/COBOL"
      }}>{`COBOL`}</a>{`について紹介しました。
軽い気持ちで調べはじめましたが、
「レセコンが`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/OSS"
      }}>{`OSS`}</a>{`で配布されている」ということにまず衝撃を受け、
「`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/ORCA"
      }}>{`ORCA`}</a>{`（日レセ）のコードが`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/CVS"
      }}>{`CVS`}</a>{`で公開されている」ということにも衝撃を受け、
「コードが`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/COBOL"
      }}>{`COBOL`}</a>{`である」ということにさらに衝撃を受けました。
が、同時に`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/OSS"
      }}>{`OSS`}</a>{`が日本の医療を支えていることに胸が熱くなりました。技術スタックが古くとも、とても素晴らしいことだと思いました。`}</p>
    <p>{`また、リニューアル PJ もあるようで、今後の動向が興味深いですね。
`}<a parentName="p" {...{
        "href": "https://www.slideshare.net/ShinjiKobayashi2/moss9-orca"
      }}>{`ORCA 次期 FW 開発の現状`}</a>{` （少し古い資料ですが）`}</p>
    <p><a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/COBOL"
      }}>{`COBOL`}</a>{`についても、調べはじめるとなかなかに興味深い言語でしたが、やはり基本文法を理解しただけではレセコンのコードを読んで理解するまでには至りませんでした。。発表資料でも触れましたが、日レセを構成する`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/%A5%DF%A5%C9%A5%EB%A5%A6%A5%A7%A5%A2"
      }}>{`ミドルウェア`}</a>{`である MONTSUQI についても、ある程度理解する必要があると感じました（俺たちの戦いはこれからだ、、）`}</p>
    <p>{`さて、医療に関わるソフトウェア＆（個人的に）未知の技術である`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/COBOL"
      }}>{`COBOL`}</a>{`に触れ、心が豊かになったところで今回のブログを締めさせていただきたいと思います。
ここまでお読み下さり、どうもありがとうございました。`}</p>
    <p>{`※勉強会資料は下記`}</p>
    <iframe id="talk_frame_387815" style={{
      "border": "0",
      "padding": "0",
      "margin": "0",
      "background": "transparent"
    }} src="https://speakerdeck.com/player/ba72db0a5f7a47f4805636fe43894b24" width="710" height="463" frameBorder="0" allowFullScreen="true"></iframe>
    <cite className="hatena-citation"><a href="https://speakerdeck.com/medley/orcatocobolto-watashi">speakerdeck.com</a></cite>
    <h1>{`最後に`}</h1>
    <p>{`メドレーでは医療業界に存在する課題に IT を駆使して取り組んでいきたいデザイナー・エンジニアを募集中です。`}</p>
    <p>{`皆さまからのご応募お待ちしております。`}</p>
    <iframe className="embed-card embed-webcard" style={{
      "display": "block",
      "width": "100%",
      "height": "155px",
      "maxWidth": "500px",
      "margin": "10px 0px"
    }} title="募集職種 | 株式会社メドレー" src="https://hatenablog-parts.com/embed?url=https%3A%2F%2Fwww.medley.jp%2Fjobs%2F" frameBorder="0" scrolling="no"></iframe>
    <cite className="hatena-citation"><a href="https://www.medley.jp/jobs/">www.medley.jp</a></cite>
    <iframe className="embed-card embed-webcard" style={{
      "display": "block",
      "width": "100%",
      "height": "155px",
      "maxWidth": "500px",
      "margin": "10px 0px"
    }} title="デザイナー＆エンジニア採用 | 株式会社メドレー" src="https://hatenablog-parts.com/embed?url=http%3A%2F%2Fwww.medley.jp%2Frecruit%2Fcreative.html" frameBorder="0" scrolling="no"></iframe>
    <cite className="hatena-citation"><a href="https://www.medley.jp/recruit/creative.html">www.medley.jp</a></cite>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      